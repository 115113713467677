import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';

document.addEventListener('alpine:initializing', () => {
    Alpine.data('main', () => ({
        showSearch: false,
        showMobileNavigation: false,
        headerScrolledAway: false,
    }));
});

Livewire.start();
